import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { fromJS } from 'immutable';
import { ConnectedRouter } from 'connected-react-router';

import AppRoutes from './routes.jsx';
import configureStore, { history } from './redux/configureStore';

import 'core-js/stable';
import 'appkit/js/Dropdown';
import 'sumoselect/sumoselect.css';
import 'appkit/sass/appkit.scss';
import 'appkit/js/Accordion';
import 'appkit/js/Select';
import 'appkit/js/Alert';
// theme css file
import 'sumoselect';
import './ui-components/Animation/Animation.scss';
import { Loader } from './ui-components/Loader';
import '../assets/css/font-awesome.min.css';
/* eslint-disable */
import axiosInterceptor from './redux/axios/axiosInterceptor';
//temp imported so they should be included in asset folder after build
import appleQRCode from '../assets/images/component/apple-store-qr-code.png';
import googleQRCode from '../assets/images/component/play-store-qr-code.png';
import CSRFTokenWrapper from './modules/csrftokenwrapper/components/csrftokenwrapper';
import AppInsights from './AppInsights.js';
/* eslint-enable */

const store = configureStore();

export default store;

let initialState = {};
AppInsights.initialize(__CONFIG__.appInsightsKey);

// rehydrate initialState for JS app
/* eslint-disable no-underscore-dangle */
if (window.__INITIAL_STATE__) {
  initialState = window.__INITIAL_STATE__;
  /* eslint-enable no-underscore-dangle */

  // Transform into Immutable.js collections,
  // but leave top level keys untouched for Redux
  Object.keys(initialState).forEach((key) => {
    initialState[key] = fromJS(initialState[key]);
  });
}

// Render the React application to the DOM
// Root component is to bootstrap Provider, Router and DevTools

// the history object provided to router reducer, routerMiddleware, and
// ConnectedRouter component must be the same history object.
ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <React.Fragment>
        <CSRFTokenWrapper />
        <AppRoutes history={history} />
        <Loader />
      </React.Fragment>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('app-container'),
);
